import $ from 'jquery';
import Routing from 'routing';
import LoadingModal from './LoadingModal';
import Modal from './Modal';
import SuperSlider from './SuperSlider';
import RequestFailedError from './error/RequestFailedError';
import TimeoutError from './error/TimeoutError';
import ValidationError from './error/ValidationError';

/* eslint-disable camelcase */

export interface E1Response {
  success: boolean;
  errors?: { [k: string]: string } | string[];
  modal_string?: string;
  slider_string?: string;
  slider_name?: string;
  close_modal?: boolean;
  close_slider?: boolean;
  form_string?: string;
  form_replace_message?: string;
  /* For triggering an AppCues flow manually */
  appcues_flowId?: string;
  extra_request?: string;
  extra_event?: string;
  redirect?: string;
  reload_page?: boolean;
  flash_notification?: FlashMessageInterface | string;
  template?: string;
}

export enum FlashMessageType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  E1_SUCCESS = 'e1-success',
}

interface FlashMessageInterface {
  title: string;
  message?: string;
  url?: string;
  icon?: string;
  target?: string;
  type?: FlashMessageType;
  ttl?: number;
}

export const flashMessageDefaultOptions = {
  placement: {
    from: 'bottom',
    align: 'left',
  },
  z_index: 9999,
  allow_dismiss: true,
  /* eslint-enable */
  animate: {
    enter: 'animated fadeInDown',
    exit: 'animated fadeOutUp',
  },
  offset: {
    x: 45,
    y: 45,
  },
};

export enum E1RequestMethod {
  GET = 'get',
  POST = 'post',
}

type E1RequestOptions<TRequestData> = {
  url: string;
  method?: string;
  data?: TRequestData;
  passive?: boolean;
  onCloseModal?: () => void;
};

export default class E1Request<
  ResponseData extends E1Response,
  RequestData extends Record<string, unknown> = Record<string, unknown>,
> {
  public appendUserId: boolean;

  public current: JQuery.jqXHR | null = null;

  /**
   * @deprecated Do not use. We should use promises (async / await) in favour of callbacks
   */
  public extraCallback?: (response: ResponseData) => void;

  // eslint-disable-next-line camelcase
  public show_loading_modal: boolean;

  public loadingModal: LoadingModal | SuperSlider | 'slider' | null;

  constructor(
    public url: string,
    public method?: string,
    public data?: RequestData,
    public passive?: boolean,
    public onCloseModal?: () => void,
  ) {
    this.url = url;
    this.method = method;
    this.appendUserId = true;
    this.data = data !== null && typeof data === 'object' ? data : ({} as RequestData);
    this.passive = Boolean(passive || false);
    this.current = null;

    this.show_loading_modal = false;
    this.loadingModal = null;
    this.onCloseModal = onCloseModal;
  }

  static fromOpts<
    TResponseData extends E1Response,
    TRequestData extends Record<string, unknown> = Record<string, unknown>,
  >(opts: E1RequestOptions<TRequestData>) {
    return new E1Request<TResponseData, TRequestData>(
      opts.url,
      opts.method,
      opts.data,
      opts.passive,
      opts.onCloseModal,
    );
  }

  setShowLoadingModal(showLoading = true) {
    this.show_loading_modal = showLoading;
    return this;
  }

  setPassive(passive = true) {
    this.passive = passive;
    return this;
  }

  setExtraCallback(callback: (response: ResponseData) => void) {
    this.extraCallback = callback;
    return this;
  }

  preSubmit() {
    if (this.show_loading_modal) {
      if (this.loadingModal === null) {
        this.loadingModal = new LoadingModal();
      }
      // FIXME - why is loadingModal being set to 'slider'
      if (this.loadingModal === 'slider') {
        this.loadingModal = new SuperSlider(undefined, { loading: true });
      }

      this.loadingModal.show();
    }
  }

  async addErrorEvent(code: number, statusText: string, readyState: number): Promise<void> {
    if (window.analyticsService) {
      await window.analyticsService.addEvent('error', {
        action: 'RequestErrorModal',
        requestUrl: this.url,
        code,
        statusText,
        readyState,
        value: code,
      });
    }
  }

  static async fetchError(
    code: number,
    statusText: string,
    readyState: number,
  ): Promise<E1Response> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      $.ajax({
        url: Routing.generate('app_ajax_error', { code, statusText, readyState }),
        data: {},
        method: 'GET',
      })
        .done(resolve)
        .fail(reject);
    });
  }

  async showError(code: number, statusText: string, readyState: number): Promise<Modal | void[]> {
    if (code === 503) {
      // website is unavailable. grab the embedded modal
      return new Modal($('.e1-error-modal.unavailable').prop('outerHTML')).show();
    }
    try {
      const response = await E1Request.fetchError(code, statusText, readyState);
      return Promise.all([
        E1Request.successResponse(this, response),
        this.addErrorEvent(code, statusText, readyState),
      ]);
    } catch (err) {
      const errReadyState = err instanceof XMLHttpRequest ? err.readyState : XMLHttpRequest.DONE;
      return this.showError(503, 'Service unavailable', errReadyState);
    }
  }

  static flashNotification(notification: FlashMessageInterface | string): void {
    if (typeof notification === 'object') {
      this.flashMessage(notification);
    } else {
      $.notify(
        { message: notification, title: 'Success' },
        {
          type: 'e1-success',
          ...flashMessageDefaultOptions,
        },
      );
    }
  }

  static flashMessage({
    message,
    title,
    url,
    icon,
    target,
    type,
    ttl: timer,
  }: FlashMessageInterface): void {
    const options = message ? { message, title } : { message: title };

    $.notify(
      { ...options, ...{ url, icon, target } },
      {
        type,
        timer,
        ...flashMessageDefaultOptions,
      },
    );
  }

  static async successResponse<
    ResponseData extends E1Response,
    RequestData extends Record<string, unknown>,
  >(self: E1Request<ResponseData, RequestData>, response: ResponseData): Promise<void> {
    if (!response) return;
    if (response.redirect) {
      window.location.assign(response.redirect);
      return;
    }

    if (response.extra_event) {
      document.dispatchEvent(new CustomEvent(response.extra_event));
    }
    if (response.modal_string) {
      if (typeof response.modal_string !== 'string') {
        throw new Error('Invalid type for modal_string');
      }
      Modal.closeAll();
      const modal = new Modal(response.modal_string, undefined, self.onCloseModal);
      modal.$modal.attr('data-content-uri', self.url);
      modal.show();
    }
    if (response.slider_string) {
      if (typeof response.slider_string !== 'string') {
        throw new Error('Invalid type for slider_string');
      }
      new SuperSlider(response.slider_string, { name: response.slider_name }).show();
    }
    if (response.close_modal) {
      Modal.closeAll();
    }
    if (response.close_slider) {
      SuperSlider.closeAll();
    }
    if (response.appcues_flowId) {
      if (Appcues !== undefined) {
        Appcues.show(response.appcues_flowId);
      } else {
        // eslint-disable-next-line no-console
        console.info(
          `Appcues: triggering flowId ${response.appcues_flowId} suppressed in dev environment`,
        );
      }
    }
    if (response.extra_request) {
      await new E1Request(response.extra_request).submit();
    }

    if (response.reload_page) {
      window.location.reload();
    }
    if (response.flash_notification) {
      E1Request.flashNotification(response.flash_notification);
    }
    if (self.extraCallback) {
      self.extraCallback(response);
    }
  }

  static errorResponse<
    ResponseData extends E1Response,
    RequestData extends Record<string, unknown>,
  >(
    obj: unknown,
    response: JQueryXHR,
    textStatus: string,
    self: E1Request<ResponseData, RequestData>,
  ): void {
    // If we have an error something went wrong server side. Show error modal unless disabled
    if (textStatus !== 'abort') {
      if (!self.passive) {
        // Handle client timeouts
        if (textStatus === 'timeout') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          self.showError(499, response.statusText, response.readyState);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          self.showError(response.status, response.statusText, response.readyState);
        }
      }
    }
  }

  async submit(
    successHandler?: (context: unknown, data: ResponseData, _: unknown) => void,
    errorHandler?: (context: unknown, data: JQueryXHR, textStatus: string, _: unknown) => void,
    obj?: unknown,
  ): Promise<ResponseData> {
    this.preSubmit();

    const context = obj || this;
    const successFunc = successHandler || E1Request.successResponse;
    const errorFunc = errorHandler || E1Request.errorResponse;

    return new Promise((resolve, reject) => {
      this.current = $.ajax({
        url: this.url,
        data: this.data,
        type: this.method,
        dataType: 'json',
        beforeSend: (request) => {
          const token = E1Request.getCsrfToken();
          if (token) {
            request.setRequestHeader('X-CSRF-TOKEN', token);
          }
          if (this.appendUserId) {
            const userId = E1Request.getUserId();
            if (userId) {
              request.setRequestHeader('X-Application-User-Ref', userId.toString());
            }
          }
        },
      })
        .done((data: ResponseData) => {
          successFunc(context, data, this);
          if (this.current && typeof this.current === 'object') {
            // HTTP 204 has no content, but should be considered successful
            if (this.current.status === 204) {
              return resolve(data);
            }
          }
          if (data.errors) {
            return reject(new ValidationError(data));
          }
          // Assume successful if success param omitted (e.g. when using forms)
          if (data.success === undefined || data.success) {
            return resolve(data);
          }
          // TODO: Handle {success:false} response
          return reject(Error('Malformed success response - success was false'));
        })
        .fail((data, textStatus: string, errorThrown: string) => {
          switch (textStatus) {
            case 'abort':
              // Aborted requests are deliberate and not an error condition
              return false;
            case 'timeout':
              errorFunc(context, data, textStatus, this);
              return reject(new TimeoutError(data, errorThrown));
            default:
              errorFunc(context, data, textStatus, this);
              return reject(new RequestFailedError(data, undefined, errorThrown));
          }
        })
        .always(
          () => this.loadingModal && this.loadingModal !== 'slider' && this.loadingModal.hide(),
        );
    });
  }

  isPending(): boolean {
    return this.current && this.current.readyState ? this.current.readyState < 4 : false;
  }

  abort(): void {
    if (this.current !== null) {
      this.current.abort();
    }
  }

  static getUserId(): number | null {
    return window?.global?.user?.id || null;
  }

  static getCsrfToken(): string | null {
    return $('meta[name=_token]').attr('content') || null;
  }
}
