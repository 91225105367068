export enum QuoteFileAction {
  Download = 'download',
  Remove = 'remove',
}

export enum QuoteReturnRoute {
  ProjectSliderMultiStage = 1,
  ProjectSliderSingleStage = 2,
  ViewQuote = 3,
  SubbieQuoteList = 4,
  SubbieInvites = 5,
  BuilderInvites = 6,
  BuilderQuotesTable = 7,
  BuilderPackageDashboard = 8,
  BuilderImportedPackageDashboard = 9,
}

export enum QuoteStatus {
  Viewed,
  Shortlisted,
  NotViewed,
  Downloaded,
  NotDownloaded,
}
