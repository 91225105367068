import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'js-cookie';
import UUID from './uuid';

export default function initialiseTracking(
  fingerprintKey: string,
  cookieExpires: Date,
  uuidKey: string,
) {
  const uuid = new UUID();
  const lastFingerPrint = Cookies.get(fingerprintKey) || null;

  Fingerprint2.getV18((browserFingerprint) => {
    if (lastFingerPrint !== browserFingerprint) {
      Cookies.set(fingerprintKey, browserFingerprint, { expires: cookieExpires });
    }
  });

  if (!Cookies.get(uuidKey)) {
    Cookies.set(uuidKey, uuid.generate(), { expires: cookieExpires });
  }
}
