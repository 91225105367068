import $ from 'jquery';
import Cookies from 'js-cookie';
import Form from '../classes/Form';

$(() => {
  $('body').on('submit', 'form.auth-container-form', (e) => {
    const $form = $(e.currentTarget);
    const $authContainer = $form.closest('.form-container');
    const magicForm = new Form($form);

    Cookies.set('send_account_traits_to_segment', true, {
      expires: 1,
      domain: '.estimateone.com',
    });

    magicForm.extraCallback = (response) => {
      if (response.auth_container_content) {
        $authContainer
          .html(response.auth_container_content)
          .find('input[type=password]')
          .trigger('focus');
      }
    };
    magicForm.submit();
    return false;
  });
  $("[data-hook='reveal-after-page-load']").removeClass('hidden');

  const $emailField = $('#user_log_in_email');
  const $resetPasswordLink = $('#reset-password-link');
  if ($resetPasswordLink.length && $emailField.length) {
    $resetPasswordLink.on('click', () => {
      const $email = $emailField.val().trim();
      if ($email) {
        $resetPasswordLink.attr(
          'href',
          `${$resetPasswordLink.attr('href')}?email=${encodeURIComponent($email)}`,
        );
      }
    });
  }
});
